<div class="notification-container d-flex align-items-center" [class.compact-mode]="compactMode()" *transloco="let t">
  <div class="notification-badge-container d-flex">
    @if (!isNotificationDataRead()) {
      <span class="cx-badge-notification-sm"></span>
    }
  </div>
  <mat-icon class="notification-icon" [svgIcon]="notificationDataType() | notificationIcon" />
  <div class="flex-grow-1 d-flex flex-column" #notificationTextContainer>
    @switch (notificationDataType()) {
      @case (notificationType.DELAYED_PROCESS) {
        @let delayedNotificationText = t(notificationDataType() | notificationInfoText, { rpId: notificationDataRPId() });
        <div class="notification-text"
             [class.font-s]="compactMode()"
             [class.ignore-white-space]="compactMode()"
             #notificationDelayedRowText
             [matTooltip]="notificationDelayedRowText.textContent"
             [matTooltipDisabled]="!showDelayedTextTooltip()"
            [innerHTML]="delayedNotificationText"></div>
      }
      @default {
        @let defaultNotificationText = t(notificationDataType() | notificationInfoText, { customer: notificationDataCustomerName() });
        <div class="notification-text"
             [class.font-s]="compactMode()"
             #notificationDefaultRowText
             [matTooltip]="notificationDefaultRowText.textContent"
             [matTooltipDisabled]="!showDefaultTextTooltip()"
             [innerHTML]="defaultNotificationText"></div>
      }
    }
  </div>
  <div class="notification-read-icon">
    <button mat-icon-button
            type="button"
            [class.small-notification-button]="compactMode()"
            [class.mr-xs]="compactMode()"
            [matTooltip]="t(isNotificationDataRead() ? 'Mark as unread': 'Mark as read')"
            (click)="onNotificationReadChange()">
      <mat-icon>
        @if (!isNotificationDataRead()) {
          check_circle_outline
        } @else {
          check_circle
        }
      </mat-icon>
    </button>
  </div>
</div>
