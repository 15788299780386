import { Pipe, PipeTransform } from '@angular/core';

import { NotificationType } from '../../../../shared/enums/notifications/notification-type.enum';

@Pipe({
  name: 'notificationIcon',
  standalone: true
})
export class NotificationIconPipe implements PipeTransform {

  public transform(notificationType: NotificationType): string {
    switch (notificationType){
    case NotificationType.APPROVAL_REQUIRED_PROCESS_EXISTS:
      return 'approval_required_log';
    case NotificationType.APPROVED_PROCESS_EXISTS:
      return 'approved_log';
    case NotificationType.REJECTED_PROCESS_EXISTS:
      return 'rejected_log';
    case NotificationType.DELAYED_PROCESS:
      return 'delayed_icon';
    case NotificationType.NEWLY_CREATED_PURCHASE_EXISTS:
      return 'notification_new_rp_icon';
    default:
      return 'approval_required_log';
    }
  }

}
