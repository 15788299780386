import { Pipe, PipeTransform } from '@angular/core';

import { NotificationType } from '../../../../shared/enums/notifications/notification-type.enum';

@Pipe({
  name: 'notificationInfoText',
  standalone: true
})
export class NotificationInfoTextKeyPipe implements PipeTransform {

  public transform(notificationType: NotificationType): string {
    switch (notificationType) {
    case NotificationType.NEWLY_CREATED_PURCHASE_EXISTS:
      return '{ customer } has purchases with status newly created';
    case NotificationType.APPROVED_PROCESS_EXISTS:
      return '{ customer } has approved processes';
    case NotificationType.REJECTED_PROCESS_EXISTS:
      return '{ customer } has rejected processes';
    case NotificationType.APPROVAL_REQUIRED_PROCESS_EXISTS:
      return '{ customer } Your Service provider asks for your approval';
    case NotificationType.DELAYED_PROCESS:
      // eslint-disable-next-line @stylistic/max-len
      return 'Status for { rpId } has not been changed for more than 14 days, please check the replenishment process and change the status accordingly to the physical workflow of the item';
    default:
      return '';
    }
  }

}
