import { computed, inject } from '@angular/core';

import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, updateAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, mergeMap, pipe, switchMap, tap } from 'rxjs';

import { LayoutStoreFacade } from '../../../store/layout/layout-store.facade';
import { NotificationData } from '../../interfaces/notifications/notification-data';
import { NotificationUpdateData } from '../../interfaces/notifications/notification-update-data';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ResiSnackbarService } from '../../services/utils/resi-snackbar.service';

export const NotificationsStoreService = signalStore(
  { providedIn: 'root' },
  withState<{ loading: boolean }>({ loading: true }),
  withEntities<NotificationData>(),
  withComputed(store => ({
    isLoading: computed(() => store.loading()),
    hasNotifications: computed(() => store.ids().length > 0),
  })),
  withMethods(store => {
    const notificationsService = inject(NotificationsService);
    const snackbarService = inject(ResiSnackbarService);
    const layoutFacade = inject(LayoutStoreFacade);
    return {
      loadNotifications: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { loading: true })),
          switchMap(() =>
            notificationsService.getNotifications().pipe(tapResponse(
              (result: Array<NotificationData>) => {
                layoutFacade.loadUnreadNotificationsCount();
                patchState(store, setAllEntities(result), { loading: false });
              },
              () => {
                snackbarService.showGeneralError();
                patchState(store, { loading: false });
              }
            ))
          )
        )
      ),
      updateNotification: rxMethod<NotificationUpdateData>(
        pipe(
          mergeMap((data: NotificationUpdateData) =>
            notificationsService.updateNotifications([ data ]).pipe(tapResponse(
              () => {
                patchState(store, updateEntity({ id: data.notificationId, changes: { isRead: data.isRead }}));
                if (data.isRead) {
                  layoutFacade.decrementUnreadNotificationCountByOne();
                } else {
                  layoutFacade.incrementUnreadNotificationCountByOne();
                }
              },
              () => snackbarService.showGeneralError()
            ))
          )
        )
      ),
      markAllAsRead: rxMethod<void>(
        pipe(
          switchMap(() => {
            const nonReadNotificationIds: Array<NotificationUpdateData> = [];
            const allNonReadNotifications = store.entities().filter((notification: NotificationData) => !notification.isRead);
            const allNonReadNotificationIds = allNonReadNotifications.map((notification: NotificationData) => notification.id);
            allNonReadNotificationIds.forEach((notificationId: string) => {
              nonReadNotificationIds.push({ notificationId, isRead: true });
            });
            if (nonReadNotificationIds?.length > 0) {
              return notificationsService.updateNotifications(nonReadNotificationIds).pipe(tapResponse(
                () => {
                  patchState(store, updateAllEntities({ isRead: true }));
                  layoutFacade.setUnreadNotificationToZero();
                },
                () => snackbarService.showGeneralError()
              ));
            } else {
              return EMPTY;
            }
          })
        )
      )
    };
  })
);
