import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpService } from '@bbraun/helpex/src/lib/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { NotificationData } from '../../interfaces/notifications/notification-data';
import { NotificationUpdateData } from '../../interfaces/notifications/notification-update-data';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private httpService: HttpService) { }

  public getNotifications(): Observable<Array<NotificationData>> {
    return this.httpService
      .request('v1/notifications')
      .get(environment.backendUrls.NOTIFICATION);
  }

  public updateNotifications(notificationsToUpdate: Array<NotificationUpdateData>): Observable<void> {
    const body = {
      notificationsToUpdate
    };
    return this.httpService
      .request('v1/notifications')
      .body(body)
      .patch(environment.backendUrls.NOTIFICATION);
  }

  public getUnreadNotificationsCount(): Observable<number> {
    const params = new HttpParams()
      .set('isRead', 'false');
    return this.httpService
      .request('v1/notifications/count')
      .params(params)
      .get(environment.backendUrls.NOTIFICATION);
  }
}
