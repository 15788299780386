import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  OnDestroy,
  signal,
  viewChild
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { TranslocoDirective } from '@jsverse/transloco';

import { NotificationType } from '../../../shared/enums/notifications/notification-type.enum';
import { NotificationData } from '../../../shared/interfaces/notifications/notification-data';
import {
  NotificationsStoreService
} from '../../../shared/signal-stores/notifications-store/notifications-store.service';
import { NotificationIconPipe } from '../pipes/notification-icon/notification-icon.pipe';
import { NotificationInfoTextKeyPipe } from '../pipes/notification-info-text-key/notification-info-text-key.pipe';

@Component({
  selector: 'app-notification-row',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatIconButton,
    MatIcon,
    MatTooltip,
    NotificationIconPipe,
    NotificationInfoTextKeyPipe
  ],
  templateUrl: './notification-row.component.html',
  styleUrl: './notification-row.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRowComponent implements AfterViewInit, OnDestroy {
  public showDelayedTextTooltip = signal<boolean>(false);
  public showDefaultTextTooltip = signal<boolean>(false);

  private notificationDelayedTextElement = viewChild<ElementRef>('notificationDelayedRowText');
  private notificationDefaultTextElement = viewChild<ElementRef>('notificationDefaultRowText');
  private notificationTextContainerElement = viewChild<ElementRef>('notificationTextContainer');

  public notificationData = input.required<NotificationData>();
  public compactMode = input<boolean>(false);

  public notificationDataRPId = computed(
    () => this.notificationData().content?.replenishmentProcess?.processId ?? '');
  public notificationId = computed(
    () => this.notificationData().id
  );
  public isNotificationDataRead = computed(() =>
    this.notificationData().isRead
  );
  public notificationDataCustomerName = computed(() =>
    this.notificationData().customer.name
  );
  public notificationDataType = computed(() =>
    this.notificationData().notificationType
  );

  protected notificationStore = inject(NotificationsStoreService);
  protected readonly notificationType = NotificationType;

  private textContainerObservable: ResizeObserver | undefined;

  public ngAfterViewInit(): void {
    if (this.notificationTextContainerElement() && this.compactMode()) {
      this.textContainerObservable = new ResizeObserver(() => {
        this.checkForTooltipVisibility();
      });
      this.textContainerObservable.observe(this.notificationTextContainerElement()!.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    if (this.textContainerObservable) {
      this.textContainerObservable.disconnect();
    }
  }

  public onNotificationReadChange(): void {
    this.notificationStore.updateNotification(
      { notificationId: this.notificationId(), isRead: !this.isNotificationDataRead() }
    );
  }

  private checkForTooltipVisibility(): void {
    if (this.compactMode()) {
      if (this.notificationDataType() === NotificationType.DELAYED_PROCESS) {
        this.checkDelayedTextTooltipVisibility();
      } else {
        this.checkDefaultTextTooltipVisibility();
      }
    }
  }

  private checkDelayedTextTooltipVisibility(): void {
    if (this.notificationDelayedTextElement()) {
      const elementRef = this.notificationDelayedTextElement()?.nativeElement;
      if (elementRef) {
        this.showDelayedTextTooltip.set(elementRef.clientHeight < elementRef.scrollHeight);
      }
    }
  }

  private checkDefaultTextTooltipVisibility(): void {
    if (this.notificationDefaultTextElement()) {
      const elementRef = this.notificationDefaultTextElement()?.nativeElement;
      if (elementRef) {
        this.showDefaultTextTooltip.set(elementRef.clientHeight < elementRef.scrollHeight);
      }
    }
  }

}
